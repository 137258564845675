<template>
  <v-card tile flat :color="$vuetify.theme.dark ? '' : '#fafafa'" height="100%">
    <v-card-title class="py-4">
      <v-spacer></v-spacer>
      <v-btn color="success" class="mx-2 text-capitalize" @click="redirectToList()">{{ $t('goToList') }}</v-btn>
      <v-btn color="primary" class="text-capitalize" @click="redirectToForm()" v-if="canCreateProject">{{ $t('createProject') }}</v-btn>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels v-model="panel" multiple flat>
        <v-expansion-panel v-for="(item, borderStatusIndex) in boardStatus" :key="borderStatusIndex" class="reducepadding mb-1" :readonly="!item.count">
          <v-expansion-panel-header :expand-icon="item.count ? 'mdi-menu-down' : ''" :color="$vuetify.theme.dark ? '' : 'grey lighten-3'"> {{ $t(item.label) }}
            <v-spacer></v-spacer>
            <span class="text-end mr-4">
              <v-chip color="info" small> {{ item.count }} </v-chip>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-2">
            <!-- <span class="px-6 text-capitalize font-weight-medium">
              {{ $t('total') }}: {{ item.total }}
            </span> -->
            <div class="py-2">
              <v-card v-for="item in item.projects" :key="item.id" class="pa-2 mx-6 mb-1" @click="redirectToView(item._id)">
                <v-container fluid class="pa-1">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <span style="color:#0091ae">{{item.data.name}}</span>
                    </v-col>
                    <v-col cols="12"  v-if="item.data.priority">
                      {{ $t('priority') }} : {{item.data.priority}}
                    </v-col>
                    <v-col cols="12" v-if="item.data.end_date">
                      {{ $t('endDate') }} : {{ getFormattedDate(item.data.end_date) }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      filter: 1,
      boardStatus: [],
      selectedDropItem: {},
      changedList: [],
      panel: [],
      listOfStages: [],
      canCreateProject: true
    }
  },
  computed: {
    ...mapGetters(['userDetails'])
    // listOfFilters () {
    //   return [{
    //     id: 1,
    //     title: 'All Deals'
    //   }, {
    //     id: 2,
    //     title: 'My Deals'
    //   }, {
    //     id: 3,
    //     title: 'My won deals this month'
    //   }, {
    //     id: 4,
    //     title: 'New deals this month'
    //   }]
    // }
  },
  watch: {
    // '$store.state.common.listOfModules' (val) {
    //   this.checkPermission()
    //   return ''
    // }
  },
  mounted () {
    this.getListOfProjectsStage()
    // this.checkPermission()
  },
  methods: {
    getListOfProjectsStage () {
      const query = [{ $match: { Module_Id: this.$route.params.id } }]
      this.$api.execute('post', 'modulefields/query', query)
        .then((result) => {
          if (result && result.data) {
            this.listOfStages = result.data
            const stage = result.data.find(x => x.name === 'status')
            let options = []
            if (stage) options = stage.default_value ? JSON.parse(stage.default_value).options : {}
            options.forEach(element => {
              if (!element.projects) {
                element.projects = []
                element.total = element.count = 0
              }
            })
            this.boardStatus = options
          }
        }).finally(() => {
          this.getDashboardItems()
        })
    },
    getDashboardItems () {
      this.$api.execute('get', 'projects/get_dashboard').then(response => {
        const stages = response.data
        const newStatusList = []
        this.boardStatus.forEach((status) => {
          const result = stages.find(x => x.stage === status.value)
          if (result) {
            newStatusList.push({ ...status, ...result })
          } else newStatusList.push({ ...status })
        })
        this.boardStatus = newStatusList
      })
    },
    redirectToList () {
      this.$router.push(`/module/${this.$route.params.name}/${this.$route.params.id}`)
    },
    redirectToForm () {
      this.$router.push(`/module/${this.$route.params.name}/${this.$route.params.id}/actions`)
    },
    redirectToView (id) {
      this.$router.push(`/module/${this.$route.params.name}/${this.$route.params.id}/view/${id}`)
    },
    updateBoardValues (event) {
      const model = this.$formatter.cloneVariable(this.selectedDropItem)
      this.boardStatus = this.$formatter.cloneVariable(this.changedList)
      if (this.selectedDropItem) {
        this.$api.execute('put', `/moduledata/${this.$route.params.name}/${model._id}`, model)
      }
      this.selectedDropItem = {}
      this.changedList = {}
    },
    selectedItem (item) {
      this.selectedDropItem = item
    },
    checkPermission () {
    //   if (this.listOfModules.length > 0) {
    //     const modulename = this.$route.params.name
    //     const value = this.listOfModules
    //     const isExists = value.find(x => x.name === modulename)
    //     if (isExists) {
    //       if (modulename === this.SALES) { // checking whether sales feature enabled for the tenant
    //         const hasAccess = this.userDetails.allowedfeatures && this.userDetails.allowedfeatures.length > 0 && this.userDetails.allowedfeatures.includes(this.SALES_AND_MARKETING)
    //         if (hasAccess) this.permissionChecking()
    //         else this.$router.push('/notavailable')
    //       } else this.permissionChecking()
    //     } else this.$router.push('/dashboard')
    //   }
    },
    permissionChecking () {
    //   const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleId, this.userDetails)
    //   this.canCreateProject = hasManagePermission
    },
    getFormattedDate (date) {
      if (date) {
        // date = this.$formatter.fromUtcToLocal(date, 'DD.MM.YYYYTHH:mm:ss')
        // date = this.$formatter.formatDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY')
        date = this.$formatter.formatDate(date, '', this.userDetails.dateformat)
      }
      return date
    }
  }
}
</script>
<style>
#sale-header {
  text-transform: uppercase !important;
}
.border-class {
  border-bottom: 1px solid rgb(223, 227, 235);
}
.reducepadding .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 0px 0px !important;
}
.reducepadding .v-expansion-panel-header {
  display: flex !important;
}
</style>
